import React, { useState } from "react"
import {
  Card,
  Container,
  Accordion,
  useAccordionToggle,
  Row,
} from "react-bootstrap"
import { Section, SectionHeading, TextBlock, LinkButton } from "../../UI/Common"
import {
  accordionCls,
  chevronCls,
  cardCls,
  customToggleCls,
} from "./FaqVariant2.module.scss"
import ArrowDown from "../../../images/icon-arrow-dropdown-icon-dropdown-down.inline.svg"

import { v4 } from "uuid"


const FaqVariant2 = ({
  sectionHeading,
  description,
  accordion,
  config,
  link,
  location,
}) => {
  const { padding } = config || {}
  const [activeKey, setActiveKey] = useState("0")
  const [itemsToShow, setItemsToShow] = useState(3)

  const CustomToggle = ({ children, eventKey, handleClick, className }) => {
    const clickHandler = useAccordionToggle(eventKey, () => handleClick())
    return (
      <div className={className} onClick={clickHandler}>
        {children}
      </div>
    )
  }

  const showmore = () => setItemsToShow(accordion.length)
  const showless = () => setItemsToShow(3)

  return (
    <>
      <Section padding={padding}>
        <Container className="text-center">
          <SectionHeading
            text={sectionHeading}
            alignment="center"
            showUnderline={false}
          />
          <TextBlock location={location} text={description} alignment="center" mode="muted" />
          <Row className="justify-content-center pt-3 pt-lg-4">
            <Accordion
              defaultActiveKey={"0"}
              activeKey={activeKey}
              className={accordionCls}>
              {accordion &&
                accordion.slice(0, itemsToShow).map((element, index) => {
                  const { label, title } = element.question
                  return (
                    <Card key={v4()} className={cardCls}>
                      <CustomToggle
                        className={customToggleCls}
                        eventKey={index.toString()}
                        handleClick={() => {
                          activeKey == index
                            ? setActiveKey(null)
                            : setActiveKey(index.toString())
                        }}>
                        <h3 className="text-left m-0">
                          {title}
                          <small className="ml-3">{label}</small>
                        </h3>
                        <ArrowDown
                          aria-label="chevron-toggle"
                          width="24"
                          height="24"
                          className={`${
                            activeKey == index ? "rotate-r-180" : ""
                          } ${chevronCls}`}
                        />
                      </CustomToggle>

                      <Accordion.Collapse
                        eventKey={index.toString()}
                        className="list-group list-group-flush">
                        <>
                          {element &&
                            element.bulletpoints &&
                            element.bulletpoints.map(bulletpoint => (
                              <li
                                key={v4()}
                                className="px-4 text-left list-group-item">
                                {bulletpoint.text}
                              </li>
                            ))}
                        </>
                      </Accordion.Collapse>
                    </Card>
                  )
                })}
              {link && (
                <LinkButton
                  title={link.title}
                  link={link.url}
                  className="mt-5"
                  variant="outlined"
                />
              )}
              {accordion.length > 3 && (
                <>
                  {itemsToShow > 3 ? (
                    <u role="button" onClick={showmore}>
                      Show More
                    </u>
                  ) : (
                    <u role="button" onClick={showless}>
                      Show Less
                    </u>
                  )}
                </>
              )}
            </Accordion>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default FaqVariant2
