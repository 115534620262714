import { graphql } from "gatsby"
import React from "react"

import FaqVariant2 from "./FaqVariant2"

export const fragment = graphql`
  fragment FaqVariant2Fragment on WpPage_Flexlayouts_FlexibleLayouts_FaqVariant2 {
    sectionHeading
    description
    tempPeacemakerAlias: accordion {
      question {
        title 
        label
      }
      bulletpoints {
        text
      }
    }
    link {
      url
      title
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFFaqVariant2 = ({ sectionHeading, description, accordion, tempPeacemakerAlias, link, config, location }) => (
  <FaqVariant2
    sectionHeading={sectionHeading}
    description={description}
    link={link}
    accordion={tempPeacemakerAlias}
    config={config}
    location={location}
  />
)
