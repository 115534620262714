// extracted by mini-css-extract-plugin
export var accordionCls = "FaqVariant2-module--accordionCls--c67bb";
export var bgGrey100 = "FaqVariant2-module--bg-grey-100--86593";
export var bgGrey150 = "FaqVariant2-module--bg-grey-150--ff7bf";
export var bgGrey200 = "FaqVariant2-module--bg-grey-200--02798";
export var bgGrey300 = "FaqVariant2-module--bg-grey-300--b54b0";
export var bgGrey400 = "FaqVariant2-module--bg-grey-400--1cb05";
export var bgGrey500 = "FaqVariant2-module--bg-grey-500--9f782";
export var bgGrey600 = "FaqVariant2-module--bg-grey-600--33dab";
export var bgGrey700 = "FaqVariant2-module--bg-grey-700--6998c";
export var bgGrey800 = "FaqVariant2-module--bg-grey-800--726a2";
export var bgGrey900 = "FaqVariant2-module--bg-grey-900--925a7";
export var cardCls = "FaqVariant2-module--cardCls--66eea";
export var chevronCls = "FaqVariant2-module--chevronCls--22c79";
export var customToggleCls = "FaqVariant2-module--customToggleCls--cd973";
export var descriptionCls = "FaqVariant2-module--descriptionCls--29c85";
export var textGrey100 = "FaqVariant2-module--text-grey-100--cf9e7";
export var textGrey150 = "FaqVariant2-module--text-grey-150--68034";
export var textGrey200 = "FaqVariant2-module--text-grey-200--ea57c";
export var textGrey300 = "FaqVariant2-module--text-grey-300--415d9";
export var textGrey400 = "FaqVariant2-module--text-grey-400--8505b";
export var textGrey500 = "FaqVariant2-module--text-grey-500--41034";
export var textGrey600 = "FaqVariant2-module--text-grey-600--0feea";
export var textGrey700 = "FaqVariant2-module--text-grey-700--d541b";
export var textGrey800 = "FaqVariant2-module--text-grey-800--80ed4";
export var textGrey900 = "FaqVariant2-module--text-grey-900--7c9e1";